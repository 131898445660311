.card-inovacao{
	max-width:350px;
	margin-right:auto;
	margin-left:auto;
	text-align:center;
	margin-bottom:30px;

	.header-card{
		position:relative;
		margin-bottom:24px;

		.controls-card{
			display:flex;
			width:100%;
			height:100%;
			justify-content:center;
			align-items:center;
			flex-wrap:wrap;
			padding:40px 28px;
			background-color:#000;
			color:#FFF;
			overflow:hidden;
			text-overflow:ellipsis;

			@include media-breakpoint-up(lg){
				opacity:0;
				transition:opacity 0.6s linear;
				position:absolute;
				top:0;
				left:0;
			}

		}

	}

	.content-card{
		color:$theme2;
		font-size:17px;
		line-height:1.2;

		.title{
			margin-bottom:16px;
			transition:color 0.6s linear;
		}

		.quadrado{
			display:inline-block;
			width:13px;
			height:13px;
			background-color:#F5F5F5;
			transition:background-color 0.6s linear;
		}

	}

	&:hover{

		.header-card{

			.controls-card{
				opacity:1;
			}

		}

		.content-card{

			.title{
				color:$theme;
			}

			.quadrado{
				background-color:$theme;
			}

		}

	}

}