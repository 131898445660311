.card-comentario{
	text-align:center;

	.card-icon,
	.desc{
		margin-bottom:15px;
	}

	.footer-card{
		color:$theme2;

		.figure-card{
			margin-bottom:6px;

			img{
				border-radius:100%;
				overflow:hidden;
			}

		}

		.nome{
			font-family: 'latoblack';
		}

	}

}