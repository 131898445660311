$icons: (
	// bg-position(x,y) width height
	'icon-aspas': -50px -76px 26px 24px,
	'icon-clip': -145px 0px 11px 25px,
	'icon-video': 0px 0px 66px 66px,
	'icon-whats': -76px 0px 59px 59px,
	'telemarketing-sm': 0px -76px 40px 42px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 156px 118px;

	@each $icon,$value in $icons {
		.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}
