@import "_home.scss";


.internas{
	padding-bottom:45px;

	.banner{

		.carousel-controls{

			.clearfix{
				padding:0;

				.float-left{
					left:-6px;
				}

				.float-right{
					right:-6px;
				}

			}

		}

	}

	.segura-carousel-sobre{

		.slick-slider{
			display:flex;
			justify-content:center;
			text-align:center;
		}

		.slick-list{

		}

		.slick-track{
			display:flex;
			justify-content:center;

			.slick-slide{
				
			}

		}

	}

	.figure-oque-fazemos{

	}

	.row.row-eventos{

		& > *{
			
			@include media-breakpoint-up(sm){
				display:flex;
				flex-wrap:wrap;
			}

			.card-agenda{
				@include media-breakpoint-up(sm){
					display:flex;
					flex-wrap:wrap;
				}

				.card-body{
					@include media-breakpoint-up(sm){
						display:flex;
						flex-wrap:wrap;
					}

					& > *{
						width:100%;
					}

					.text-center{
						@include media-breakpoint-up(sm){
							display:flex;
							flex-wrap:wrap;
							justify-content:center;
							align-items:flex-end;
						}
					}

				}

			}

		}

	}

	.internas-breadcrumb{
		list-style-type:none;
		padding-left:0;
		margin-bottom:0;
		position:relative;
		background-color:#F5F5F5;
		color:$theme2;
		font-size:14px;
		font-style:italic;
		padding:22px 5px;
		margin-bottom:50px;

		@include media-breakpoint-down(md){
			text-align:center;
		}

		@include media-breakpoint-up(sm){
			display:flex;
			align-items:center;
			flex-wrap:wrap;
			justify-content:flex-end;
		}

		@include beforeAfter{
			content:'';
			display:block;
			width:100vw;
			height:100%;
			background-color:inherit;
			position:absolute;
			top:0;
		}

		&:before{
			left:100%;
		}

		&:after{
			right:100%;
		}

		li{

			& + li{

				&:before{
					content:'|';
					display:inline-block;
					font-style:normal;
					margin:0 4px;

					@include media-breakpoint-down(xs){
						display:block;
						margin-bottom:2px;
					}

				}

			}

		}

	}

	&.internas-oque-fazemos{

		.section-objetivos{
			background-color:#F5F5F5;
			position:relative;
			padding-top:30px;
			padding-bottom:6px;

			@include beforeAfter{
				content:'';
				width:100vw;
				height:100%;
				position:absolute;
				top:0;
				background-color:inherit;
			}

			&:before{
				left:100%;
			}

			&:after{
				right:100%;
			}

		}

		.comentario{
			padding-top:45px;
		}

	}

	&.internas-depoimentos{
		padding-top:0;
		padding-bottom:0;

		.internas-breadcrumb{
			margin-bottom:0;
		}

	}

	&.internas-linha-do-tempo{

		.row-divider{
			position:relative;
			overflow:hidden;
			flex-direction:row-reverse;

			&:before{
				
				@include media-breakpoint-up(lg){
					content:'';
					display:block;
					position:absolute;
					top:0;
					left:calc(50% - 3px);
					height:200vh;
					width:3px;
					background-color:#F5F5F5;
					transform:translateX(-50%);
				}

			}

		}

	}

}

main.noticias#modelo-3{
	padding-bottom: 80px;

	.h1{
		font-weight: 400;
		margin-bottom: 30px;
		font-family: 'latosemibold';
		color:$theme2;

		span{
			font-weight: 900;
			font-family: 'latoblack';
		}

		border-bottom:1px solid rgba(#000,.3);

		&:after{
			content:'';
			display: block;
			width: 100px;
			height: 3px;
			background: $theme;
			margin-top: 7px;
			transform: translateY(4px);
		}
	}

	.noticia-text{
		margin-bottom: 20px;

		.tag{
			color: $theme;
		}

		.titulo{
			font-size: 30px;
			line-height: 1.2;
			max-height: (1em * 3 * 1.2);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			margin-bottom: 10px;
			font-weight: 900;
			color: #444;
		}

		.descricao{
			font-size: 16px;
			line-height: 1.2;
			max-height: (1em * 1.2 * 3);
			color: #777;

			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			margin-bottom: 10px;
		}

		a:hover{
			color: #000;

			.descricao{
				color: #000;
			}
		}

		.footer{
			display: flex;
			flex-wrap:wrap;
			align-items: center;
			padding:10px 0;
			border-top:1px solid rgba(#000,0.2);
			border-bottom:1px solid rgba(#000,0.3);

			.redes-sociais{
				flex-grow:1;
				color: #777;

				a{
					display: inline-block;
					margin:0 10px;

					&:hover{
						text-decoration: none;
					}
				}
			}

			.postado{
				font-size: 12px;
				color: #777;
			}
		}
	}

	.noticia-covered{
		width: 350px;
		max-width: 100%;
		position: relative;

		.tag{
			display: inline-block;
			padding:5px 10px;
			background: #EE584D;
			margin-bottom: 10px;
		}

		.caption-noticia{
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			bottom: 0;
			z-index: 5;
			padding:10px;
			background: linear-gradient(to bottom, rgba(#000,0) 0%, rgba(#000,.8) 100%);
			color: #FFF;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		.titulo{
			line-height: 1.2;
			font-weight:900;
			font-size: 25px;
			max-height: calc(1em * 1.2 * 3);
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}
	}

	.noticia-h{
		margin-bottom: 20px;
	}


	@include media-breakpoint-down(md) {
		.noticia-text{
			width: 350px;
			max-width: 100%;
		}

		.noticia-covered,
		.noticia-text{
			margin-right:auto;
			margin-left:auto;
		}
	}

	@include media-breakpoint-up(lg) {
		.noticia-h:nth-child(even){
			.image-area{
				order: 1;
			}
			.text-area{
				order: 2;
			}
		}
		.noticia-h:nth-child(odd){
			.image-area{
				order: 2;
			}
			.text-area{
				order: 1;
			}
		}
	}

	.mais-lidas{
		margin-bottom: 30px;
		border-radius: 0;
		border:none;

		.card-header{
			color: #222;
			border-radius: 0;
			font-weight: 300;
			font-size: 25px;
			border: none;
			padding-bottom:0;
			border-left:5px solid $theme;
			background: none;

			span{
				font-weight: 900;
			}
		}

		.card-body{
			border-left:5px solid $theme;
		}

		.card-footer{
			padding-left:0;
			padding-right:0;
			background: none;

			.fa-angle-right-double{
				&:after,
				&:before{
					content: fa-content($fa-var-angle-right);
				}
			}

			.btn{
				background: #333333;
				color:#FFF;
				border-radius: 0;
				text-align:left;
				padding: 15px 15px;

				&:hover{
					background: darken(#333,10%)
				}
			}
		}

		a:hover{
			text-decoration: none;

			.media-body{
				color: $theme;
				text-decoration: underline;
			}
		}

		.media{
			padding: 15px 0;
			color: #777;
			border-top: 1px solid rgba(#000,.2);

			h3{
				margin: 0;
				font-size: 16px;
				font-weight: 400;
				line-height: 1.4;
				max-height: (1em * 1.4 * 4);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
			}

			.media-body{
				padding-bottom: 15px;
			}

			a:hover{
				color: $theme;
			}
		}

		.publicado{
			font-size: 12px;
			margin-bottom: 5px;
			line-height: 1.3;

			&:before{
				content:'';
				display: inline-block;
				height: 4px;
				width: 4px;
				border-radius: 100%;
				background: $theme;
				margin-right: 5px;
			}
		}

		.rank{
			min-width: 55px;
			font-size: 45px;
			padding-right:10px;
			padding-top: 7px;
			font-weight:900;
			color: #333333;
			transition:color 0.3s linear;
		}

		a:hover .rank{
			color: lighten($theme,30%);
		}
	}

	.paginacao{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		a,
		span{
			margin: 0;
			border:1px solid rgba(#000,0.2);
			background: #FFF;
		}

		.active{
			background: $theme;
			color: #FFF;
		}

		*:not(.active):hover{
			background: #eee;
		}
	}

	.btn-load-more{
		background: $theme;
		color:#FFF;

		&:hover{
			background: darken($theme, 10%);
			color: #FFF;
		}
	}

	.noticia-detalhes{
		.header{
			margin-bottom: 40px;
			border-bottom: 3px solid #DADADA;
		}

		h1{
			border: none;
			padding-bottom: 0;
			font-weight: 900;
			font-size: 30px;
			color:#333;
			margin-bottom: 20px;

			&:after{
				display: none;
			}
		}

		.subtitulo{
			font-size: 15px;
			color: #909090;
			margin-top:0;
			margin-bottom: 20px;
			font-weight: 400;
		}

		.header-detail{
			display: flex;
			flex-wrap: wrap;
			border-top: 1px solid $theme;
			padding: 10px;

			.autor{
				flex-grow: 1;

				span{
					color: $theme;
				}
				
				@include media-breakpoint-up(md) {
					order: 2;
					text-align: right;
				}
			}

		}
		
		.redes-sociais{
			
			a{
				display: inline-flex;
				width: 2em;
				height: 2em;
				align-items:center;
				justify-content:center;
				border-radius: 100%;

				& + a{
					margin-left: 5px;
				}

				&:hover{
					text-decoration: none;
				}

				&.fa-facebook,
				&.fa-facebook-f{
					background: #3b5998;
					color: #FFF;
				}

				&.fa-twitter{
					background: #00acee;
					color: #FFF;
				}

				&.fa-instagram{
					background: #3f729b;
					background: linear-gradient(45deg, purple 0%,orange 100%);
					color: #FFF;
				}
			}

			@include media-breakpoint-up(md) {
				order: 1;
			}
		}

		img{
			max-width: 100%;
			height: auto !important;
		}

		.body{
			padding-bottom: 20px;
		}

		.footer{
			padding-top:20px;
			border-top:1px dashed rgba(#000,0.3);
		}
	}

	#album-noticias {
		display: flex;

		.prev,
		.next {
			padding: 15px 10px;
			display: flex;
			align-items: center;
			background: $theme;
			color: #FFF;

			&:hover{
				text-decoration: none;
			}
		}

		.prev{
			order: 1;
		}

		.carousel-inner{
			order: 2;
		}

		.next{
			order: 3;
		}
	}

	.redes-sociais h5{
		color: #777;
		font-weight: 400;
	}

	h3.h5{
		margin-bottom: 20px;
		color: $theme;
	}
}

.clientes-e-parceiros{
	.row.clientes{
		@include media-breakpoint-between(sm,md){
			& > *{
				&:before{
					content:'';
					position:absolute;
					height:90%;
				}
			}
		}
	}

	.cliente{
		margin-bottom:30px;
		text-align:center;
		.nome{
			text-align: center;
			color:$theme2;
			margin-top:15px;
			font-family: 'lato-bold';
		}
	}
}

#clientes-e-parceiros-mod-2{

	.row.clientes{
		align-items: center;
	}

	.paginacao{
		.active{
			background: #FFB554;
			color: #000;
		}
	}

	.titles{
		font-family: 'OFFISFB';

		&:before{
			content:'';
			display: inline-block;
			width:1em;
			height:0.2em;
			margin-bottom:0.2em;
			background: currentcolor;
			margin-right:0.5em;
		}
	}
}

svg.detail-titles{
	display:inline-block;
	vertical-align: middle;
	border:none;

	use{
		fill: currentcolor;
	}
}

.paginacao{
	margin-bottom: 60px;
	text-align:center;
	color:#333;

	& > *{
		display: inline-block;
		padding:0.3em 0.6em;
		border:2px solid #333;


		& + *{
			margin-left:0.3em;
		}
	}

	.active{
		background: #333;
		color: #FFF;
	}

	a:hover{
		background: rgba(#000,0.1);
		text-decoration: none;
	}
}

.md-form{
	position:relative;
	padding-top:1.5rem;
	padding-bottom:1rem;

	& > label{
		margin:0;
		padding:0.375rem 0.75rem;
		position:absolute;
		
		@include media-breakpoint-down(sm){
			top:0;
			padding:0;
		}

		@include media-breakpoint-up(md){
			top:1.5rem;
			transition:all 0.3s linear;
		}
	}

	.form-control{
		border-radius:0;

		&.form-control-lg ~ label{
			padding: 0.5rem 1rem;
			font-size:1.25rem;
		}

		&.form-control-sm ~ label{
			padding: 0.25rem 0.5rem;
			font-size:0.875rem;
		}
	}

	.hover-eff{
		width:0;
		height:2px;
		background:$green;
		transition:width 0.3s linear;
	}

	.form-control:focus ~ .hover-eff,
	&.active .hover-eff{
		width: 100%;
	}

	&.has-success{
		.hover-eff{
			background: green;
		}

		label{
			color: darken(green,20%) !important;
		}
	}

	&.has-error{
		.hover-eff{
			background: red;
		}

		label{
			color: red !important;
		}
	}

	&.has-warning{
		.hover-eff{
			background: darken(orange,20%);
		}

		label{
			color: darken(orange,20%) !important;
		}
	}

	@include media-breakpoint-up(md) {
		&.active {
			& > label,
			.form-control-lg ~ label,
			.form-control-sm ~ label{
				top:0;
				padding-top:0;
				font-size: 0.8rem;
			}
		}
	}
}

textarea.form-control{
	height:auto !important;
}

.select-custom{
	position:relative;

	output{
		display: block;
	}

	.drop-options{
		background: #FFF;
		padding: 0.75rem;
		border:1px solid rgba(#000,0.2);
		position:absolute;
		left:0;
		top:100%;
		width:100%;
		display: none;
		z-index:5;
	}

	input[disabled]{
		background: #FFF;
	}

	&.open{
		.drop-options{
			display: block;
		}
	}

	input[name="filtro"]{
		@extend .form-control;
		margin-bottom:10px;
	}

	ul{
		list-style:none;
		padding-left:0;
		margin-left:-0.75rem;
		margin-right:-0.75rem;
		margin-bottom:0;
		max-height: 120px;
		overflow: auto;

		label{
			padding:0.5rem 0.75rem;
			display: block;
			margin-bottom:0;

			&:hover{
				background: rgba(#000,0.1);
			}
		}
		
		input{
			position:absolute;
			display: none;

			&:checked ~ label{
				background: $blue;
				color: #fff;
			}
		}
	}
}

#alertas{
	position: fixed;
	right:15px;
	top:90px;
	width: 350px;
	max-width: calc(100% - 30px);
	z-index: 999;

	.alert{
		border-radius: 0;
		border-color: rgba(#000,0.2);
		@extend .alert-dismissible;
		box-shadow:0 0 8px rgba(#000,.4);
		animation: rubberBand 0.6s linear;

		&.alert-success{
			background: darken(green,20%);
			color: #FFF;
		}

		&.alert-danger{
			background: darken(red,20%);
			color: #FFF;
		}

		&.alert-warning{
			background: darken(orange,20%);
			color: #FFF;
		}

		&.alert-info{
			background: darken(blue,20%);
			color: #FFF;
		}
	}

	.media-body{
		align-self: center;
	}

	.icon-alerta{
		font-size: 2em;
		margin-right: 10px;
	}

	.alert-success{}
}

.calendario,
.calendario-filtro{
	width: 300px;
	max-width: 100%;
	text-align:center;
	padding:2px;
	margin-bottom: 40px;

	border-bottom: 5px solid $theme;

	$liWidth: (100% / 7);

	.calendario-head{
		background: $theme;
		color:#FFF;
		display: flex;
	}

	.ctrl-mes{
		display: block;
		border: none;
		color: #FFF;
		background: $theme;
		padding: 0.375rem 0.75rem;

		&:hover{
			background: darken($theme, 10%);
			cursor: pointer;
		}

		&:focus{
			outline: none;
		}
	}

	.nome{
		padding: 0.375rem 0.75rem;
		flex-grow: 1;
	}

	.dias-semana{
		list-style: none;
		display: flex;
		padding-left:0;
		margin-bottom: 0;
		font-size: 12px;

		li{
			padding: 0.375em;
			width: $liWidth;
			border: 1px solid #FFF;
		}
	}

	.dias{
		padding-left: 0;
		display: flex;
		flex-wrap:wrap;
		list-style: none;
		margin-bottom: 0;
		font-size: 12px;
		li{
			width: $liWidth;
			padding: 0.375em;
			border: 1px solid #fff;
			margin-bottom: -1px;
			background: #EDEDED;

			@for $i from 0 through 6{

				&[data-dia="#{$i}"]{
					margin-left: ($liWidth * $i);
				}

			}

			&.active{
				background: darken(#EDEDED, 10%);
			}
		}
	}
}

.page-agenda{}

#agenda-1{
	padding-bottom: 80px;

	.filtro-agenda{
		font-size: 20px;
		margin-bottom: 60px;
		color:#000;

		p{
			&:after{
				content:'';
				display:block;
				width: 4em;
				height:5px;
				background: $theme;
				border-radius:5px;
				margin-top: 5px;
			}
		}
	}

	.filter-box{
		width: 600px;
		max-width: 100%;
		background: #E8E8E8;
		border-radius:5px;
		border-bottom: 3px solid rgba(#000,0.1);
		display: flex;
		flex-wrap:wrap;

		.filter-box-group{
			padding: 15px;

			@include media-breakpoint-down(sm){
				width: 100%;
			}

			@include media-breakpoint-up(md){
				width: 50%;
			}
		}

		.btn{
			width: 100%;
			border:none;
			border-bottom: 3px solid rgba(#000,0.1);
			background: $theme;
			color: #FFF;
			text-shadow: -1px 1px rgba(#000,0.2);
			overflow: hidden;
			position:relative;

			&:before{
				content:'';
				display:block;
				width: 50%;
				height: 200%;
				position:absolute;
				top: 50%;
				left: -100%;
				transform: translateY(-50%) rotate(15deg);
				background-image: linear-gradient(to right, transparent 0%, rgba(#fff,0.4) 50%, transparent 100%);
			}

			&:hover{
				background: darken($theme, 10%);

				&:before{
					left:100%;
					transition: left 0.6s linear;
				}
			}
		}

		input{
			background: #dad9d9;
			border:none;
			border-bottom:3px solid rgba(#000,0.1);
			border-radius: 5px;
			padding: 0.375rem 0.75rem;
			font-size: 16px;
			width: 100%;

			&:focus{
				border-bottom:3px solid $theme;
				outline: none;
			}
		}
	}

	.listagem-agenda{
		a:hover{
			text-decoration: none;
		}
	}

	.card-agenda{
		border: none;
		background: #e9e9e9;
		border-radius: 5px;
		overflow:hidden;
		width: 270px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		.card-foto{
			margin-bottom: 0;
			position: relative;
			overflow: hidden;

			figcaption{
				position: absolute;
				top: 10px;
				left: 10px;
				z-index: 2;
				background: #495052;
				color: #FFF;
				padding: 5px 10px;
				border-radius: 5px;
				font-size: 12px;
				font-weight: 600;
			}
		}

		.card-header{
			background: #495052;
			color: #FFF;
		}

		.card-body{
			color: #777;
			border-bottom: 5px solid rgba(#000,0.2);
			transition:all 0.3s linear;
			border-radius: 0 0 5px 5px;
		}

		.svg-icon{
			width:1em;
			height: 1em;
			display: inline-block;
			vertical-align: baseline;

			use{
				fill: currentcolor;
			}
		}

		.nome,
		.data{
			padding-left: 1.5em;
			position: relative;

			.far,
			.svg-icon{
				position:absolute;
				left:0;
				top:0.15em;
			}
		}

		.description{
			margin-bottom: 10px;

			&:before{
				content:'';
				width: 3em;
				height:5px;
				background: currentcolor;
				display: block;
				margin: 10px 0;
			}
		}

		.btn{
			background: $theme;
			color: #FFF;
			border: none;
			border-bottom:3px solid rgba(#000,0.1);
		}

		&:hover{
			.card-body{
				background: darken($theme,12%);
				color:#FFF;
			}
		}
	}

	.paginacao{
		margin-top: 80px;

		& > *{
			background: $theme;
			border-radius: 5px;
			border: none;
			border-bottom: 5px solid rgba(#000,0.2);
			color: #FFF;
			padding: 0.375rem 0.75rem;
		}

		.active{
			background: #495153;
			cursor:default;
		}
	}

	.agenda-detalhes{
		padding:15px;
		position: relative;
		min-height: 90px;
		background: #efeeee;
		$bg-titulo: $theme;

		.data-flutuante{
			padding: 15px 30px;
			color: #FFF;
			background: $bg-titulo;
			top:-15px;
			left:30px;
			max-width: calc(100% - 60px);
			position: absolute;
			z-index:5;
			font-size: 20px;
			text-shadow: -1px 1px darken($bg-titulo, 20%);

			&:before{
				content:'';
				position: absolute;
				border-top:15px solid transparent;
				border-left:15px solid transparent;
				border-right:15px solid darken($bg-titulo, 20%);
				right: 100%;
				top:0;
				filter: drop-shadow(-1px 1px darken($bg-titulo, 20%));
			}

			.icon{
				margin-right: 10px;
				margin-top: 8px;
			}

			.dia{
				font-size: 30px;
				font-weight:900;
			}
		}

		.header{
			border-bottom:1px solid rgba(#000,0.2);
			margin-bottom: 20px;

			&:after{
				content:'';
				display: block;
				width: percentage(470/690);
				background: rgba(#000,0.2);
				height:3px;
				margin-top:15px;
			}

			.label{
				padding:5px 10px;
				display: inline-block;
				background: $theme;
				color: #FFF;
				margin-bottom:10px;
			}

			h2{
				font-weight: 900;
			}

			.autor{
				strong{
					color: $theme;
				}
			}

			.autor,
			.data-post{
				color: #777;
			}
		}

		.footer{
			padding-top: 15px;
			border-top:1px solid rgba(#000,0.2);
		}

		.rede-social{
			display: inline-block;
			width: 2em;
			height:2em;
			line-height:2em;
			text-align: center;
			border-radius:100%;
			margin: 0 10px;
			transition: all 0.3s linear;

			&.fa-facebook-f,
			&.fa-facebook{
				background: #3b5998;
				color:#FFF;

				&:hover{
					background: darken(#3b5998,20%);
				}
			}

			&.fa-twitter{
				background: #00acee;
				color:#FFF;

				&:hover{
					background: darken(#00acee,20%);
				}
			}

			&.fa-instagram{
				background: #3f729b;
				color:#FFF;

				&:hover{
					background: darken(#3f729b,20%);
				}
			}

			&.fa-pinterest,
			&.fa-pinterest-p{
				background: #c8232c;
				color:#FFF;

				&:hover{
					background: darken(#c8232c,20%);
				}
			}

			&.fa-linkedin,
			&.fa-linkedin-in{
				background: #0e76a8;
				color:#FFF;

				&:hover{
					background: darken(#0e76a8,20%);
				}
			}

			&:hover{
				text-decoration: none;
				box-shadow:0 0 4px rgba(#000,0.8);
			}
		}

		@include media-breakpoint-up (lg) {
			.redes-sociais{
				display: flex;
				align-items: center;

				p{
					margin-bottom: 0;
					margin-right: 10px;
				}
			}
		}
	}

	.veja-tambem{
		border:1px solid rgba(#000,.2);
		background: #FFF;
		margin-bottom: 40px;

		.header{
			border-left: 5px solid $theme;
			padding:0 15px;
			margin-top:15px;

			h2{
				margin-bottom:0;
				font-size: 25px;
			}

			p{
				color: #777;
			}
		}

		.body{
			padding: 15px;
		}

		.media{
			padding: 15px 0;
			border-top:1px solid rgba(#000,0.2);

			h3{
				font-size: 16px;
				font-weight: 900;
				line-height:1.3; 
				max-height: (16px * 1.3 * 3);
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
			}

			.foto{
				margin-right:10px;
				img{
					border-radius: 8px;
				}
			}

			p{
				color: #777;
				font-size: 14px;
				margin-bottom: 0;
			}
		}
	}

	.btn-voltar{
		background: #EFEEEE;
		color: $theme;
		border-radius:0;
		transition:all 0.3s linear;

		&:hover{
			background: $theme;
			color: #fff
		}
	}

	.calendario{
		margin-left:auto;
		margin-right:auto;
	}

	.fotos{
		h2{
			margin-bottom: 30px;
			font-size: 20px;
			font-weight: 700;

			&:after{
				content:'';
				display: block;
				width: toEm(120, 20);
				max-width: 100%;
				height: 5px;
				border-radius: 5px;
				background: $theme;
				margin-top:10px;
			}
		}

		.owl-carousel{
			margin-left:auto;
			margin-right:auto;
			width: calc(100% - 60px);

			.owl-nav{
				& > * {
					width: 30px;
					height: 100%;
					background: $theme;
					color:#FFF;
					position:absolute;
					top:0;
					overflow: hidden;
					text-indent:-999px;
					cursor:pointer;
					@extend .fas;

					&:not(.disabled):hover{
						background: darken($theme, 10%);
					}

					&:before{
						position: absolute;
						text-indent: 0;
						left:50%;
						top:50%;
						transform: translate(-50%, -50%)
					}

					&.disabled{
						cursor: default;
						background:#ddd;
						color:#444;
					}
				}

				.owl-prev{
					left:-30px;
					@extend .fa-chevron-left;
				}

				.owl-next{
					right: -30px;
					@extend .fa-chevron-right;
				}
			}
		}
	}
}

#produtos-mod-2{
	color: #777;

	.subtitle-produtos{
		font-weight: bold;
		font-size: 24px;
		color: #000;

		svg{
			color: $theme;
			margin:0;
			vertical-align: middle;
		}
	}

	.row-produtos{
		& > *{
			display: flex;
		}
	}

	.produto{
		width: 100%;
		max-width: 350px;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;

		.foto{
			margin-bottom: 0;
		}

		.nome{
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			padding:10px;
			color:$theme2;
		}

		.descricao{
			// flex-grow: 1;
			color: #777;
			padding-bottom:10px;
		}

		.btn{
			$bg: $theme;
			border-radius: 0;
			background: $bg;
			color: #FFF;

			&:hover{
				background: darken($bg, 10%);
				color:#FFF;
			}
		}
	}

	.paginacao{

		& > *{
			$color: $theme;

			color: $color;


			&.active{
				background: $color;
				color: #fff;
				border-color: $color;
			}
		}
	}

	#carousel{
		max-width: 460px;
		margin-left:auto;
		margin-right:auto;

		figure{
			margin-bottom:0;
		}

		.controls{
			$bg: $theme;
			position:absolute;
			bottom:0;
			right:0;
			background: $bg;
			display: flex;
			font-size: 20px;

			&:before{
				content:'';
				width:25px;
				height:100%;
				position:absolute;
				right:calc(100% - 4px);
				top:0;
				background: $bg;
				clip-path: polygon(0 100%, 20px 0, 100% 0, 100% 100%);
			}

			a{
				display: block;
				padding:10px;
				color: #FFF;

				&:hover{
					color: $theme2;
					background: rgba(#000,.2);
					text-decoration: none;
				}
			}
		}
	}
}

.modal-depoimento{
	color: #777;

	.autor{
		padding-bottom:15px;
		margin-bottom:30px;
		border-bottom:10px solid rgba(#000,0.1);
		color:#000;
	}

	.modal-body{
		padding:10px;
	}
}

.depoimentos#depoimento-mod-1{
	flex-grow:1;
	padding-bottom:60px;
	background-position: center top;

	.depoimento{
		background: #F6F6F7;
		border: 6px solid #fff;
		padding: 10px;
		text-align: center;
		margin:70px  10px 10px 10px;
		position: relative;
		padding-top:100px;
		box-shadow:0 0 8px rgba(#000,0.6);

		.avatar{
			width:140px;
			height:140px;
			border-radius: 100%;
			overflow:hidden;
			border:6px #FFF;
			position:absolute;
			top:-70px;
			left: calc(50% - 70px);
			border:6px solid #fff;
			box-shadow:0 0 8px rgba(#000,0.6);
		}

		.autor{
			font-family: 'latoblack';
			color:$theme2;
		}
	}
}

#carouselDepoimento{
	.ctrls{
		text-align: center;
	}

	
	@include media-breakpoint-up(sm){
		.detalhe{
			position: absolute;
			top:30px;
			left:0;
			z-index:3;
		}

		.carousel-item{
			padding:0 50px;
		}
	}

	.controls{
		@extend .btn;
		
		margin: 10px;

		@include button-variant($theme, $theme);
	}
}

.contato-page{
	#mapa-contato{
		@extend .embed-responsive;
		background: #F2F2F2;
	}
}

.contato-page#contato2{
	padding-bottom: 80px;


	#mapa-contato{
		// padding-bottom: percentage(660/1920);
		// min-height: 200px;
	}

	.pdt-40{
		padding-top: 40px;
	}

	h2{
		border-bottom: 1px solid currentcolor;
		margin-bottom: 30px;
		color:$theme2;

		&:after{
			content:'';
			display: block;
			width:60px;
			height: 2px;
			background: currentcolor;
			margin-top:10px;
		}
	}

	h3{
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}

	h4{
		font-size: 16px;
		font-weight: bold;
		color: #000;
	}

	.info-block{
		color: #777;
	}

	.select-custom{
		.output{
			position: relative;
			padding-right: calc(1em + 1.5rem);
			display:flex;
			align-items:center;

			&:before{
				content:'';
				display: block;
				position: absolute;
				border-left:0.5rem solid transparent;
				border-right:0.5rem solid transparent;
				border-top:0.5rem solid currentcolor;
				right:0.75rem;
				top:50%;
				transform: translateY(-50%);
			}
		}
	}

	.has-success{
		.form-control{
			border-color: darken(green,20%);
		}
	}

	.has-error{
		.form-control{
			border-color: darken(red,20%);
		}
	}

	.has-warning{
		.form-control{
			border-color: darken(orange,20%);
		}
	}

	.btn-enviar{
		border-radius: 0;
		@include button-variant(#61A93E, #61A93E);
		color: #FFF;

		&:hover{
			color: #FFF;
		}
	}

}

.slick-carousel-diferente{

	.slick-arrow{
		@extend .square;

		@include media-breakpoint-up(lg){
			position:absolute;
			top:calc(50% - 21px);
			transform:translateY(-50%);
			z-index:200;
		}

		&.slick-prev{
			left:0;
		}

		&.slick-next{
			right:0;
		}

	}

}