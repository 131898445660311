.custom-input-file{

	input{
		position:fixed;
		top:0;
		left:-200vw;
	}

	label{
		margin-bottom:0;
		display:flex;
		width:100%;

		& > *{
			display:flex;
			align-items:center;
		}
		.btn{
			min-width:56px;
			text-align:center;
			border-radius:0;
			justify-content:center;
		}

	}

}