.card-casos{
	margin-bottom:24px;
	max-width:342px;
	margin-right:auto;
	margin-left:auto;

	figure{
		margin-bottom:0;
		text-align:center;
	}

	.content-card{
		padding-left:20px;

		@include media-breakpoint-up(lg){
			text-align:left;
		}

		.title-card{
			color:$theme2;
			font-size:17px;
			font-family: 'lato-bold';
			padding-top:8px;
			padding-bottom:8px;
			transition:all 0.6s linear;
			position:relative;
			padding-left:28px;
			padding-right:28px;
			line-height:1.2;

			&:before{
				content:'';
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:7px;
				height:calc(100% + 52px);
				background-color:$theme;
			}

		}

	}

	&:hover{

		.content-card{

			.title-card{
				background-color:#000;
				color:#FFF;
			}

		}

	}

}