.rodape{

	.icon-whats{
		position:fixed;
		bottom:20px;
		right:20px;
		z-index:600;
	}

	.rodape-feedback{
		background-color:$theme;
		color:#FFF;
		padding-top:25px;
		padding-bottom:4px;
	}

	.rodape-principal{

		@include media-breakpoint-down(md){
			text-align:center;
		}

		.row{
			border-bottom:1px solid #F5F5F5;
			margin-bottom:10px;
		}

		.title-rodape{
			font-size:15px;
			color:$theme;
			font-family: 'lato-bold';
		}

		.menu-rodape{
			font-family: 'latosemibold';
			color:#777;
			list-style-type:none;
			margin-bottom:0;
			padding-left:0;

			& > li{

				&.active > a,
				&:hover  > a{
					color:$theme;
				}

			}

		}

		.link{

			& + .link{

				&:before{
					content:'|';
					margin:0 4px;
				}

			}

		}

		.copyright{
			font-size:10px;
			font-family: 'robotoregular';
			padding-bottom:8px;

			& > *{
				margin-bottom:10px;
			}

			img.gv8{
				max-width:44px;
			}

			.gv8-link{
				max-width:244px;
				flex-grow:1;
			}

		}

	}

}