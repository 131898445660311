.card-video{
	margin-bottom:40px;

	.video-incrivel{
		min-height:136px;
		margin-left:auto;
		margin-right:auto;
		width:100%;
		max-width:256px;
		background-position:center center;
		background-repeat:no-repeat;
		background-size:cover;
		position:relative;

		.tempo,
		.bg-controls > *{
			z-index:200;
		}

		.tempo,
		.bg-controls{
			position:absolute;
		}

		.bg-controls{
			top:0;
			left:0;
			display:flex;
			align-items:center;
			justify-content:center;
			width:100%;
			height:100%;
			background-color:rgba($theme,0.2);

			@include media-breakpoint-up(lg){
				opacity:0;
				transition:opacity 0.6s linear;
				background-color:rgba($theme,0.8);
			}

		}

		.tempo{
			bottom:1px;
			right:2px;
			background-color:#000;
			color:#FFF;
			padding:3px 4px;
			font-weight:bold;
			font-size:13px;
			min-width:43px;
			border-radius:6px;
		}

	}

	&:hover{

		.bg-controls{
			opacity:1;
		}

	}

}