.article-date{
	margin-bottom:90px;

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.ano{
		font-size:17px;
		color:$theme;
		position:relative;
		line-height:1;

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			top:0;
		}

		&:before{
			width:19px;
			height:19px;
			border:1px solid #EDEDED;
			background-color:#FFF;
		}

		&:after{
			width:9px;
			height:9px;
			top:50%;
			transform:translateY(-50%);
			background-color:$theme;
		}

	}

	.title{
		font-size:27px;
		color:$theme2;
		line-height:1.2;
		margin-bottom:22px;
		font-family: 'lato-bold';
	}

}

.col-esquerda{

	& > *{

		&:last-child{

			@include media-breakpoint-up(lg){
				margin-bottom:0;
			}

		}

	}

	@include media-breakpoint-up(lg){
		padding-top:125px;
	}

	.article-date{
		
		@include media-breakpoint-up(lg){
			padding-right:12px;
		}

		@include media-breakpoint-up(lg){
			text-align:right;
		}

		.ano{

			&:before{
				left:calc(100% + 14px);
			}

			&:after{
				left:calc(100% + 19px);	
			}

		}

	}

}

.col-direita{

	.article-date{
		
		@include media-breakpoint-up(lg){
			padding-left:12px;
		}

		.ano{

			&:before{
				right:calc(100% + 21px);
			}

			&:after{
				right:calc(100% + 26px);
			}

		}

	}

}