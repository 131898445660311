.paginacao-custom{
	flex-wrap:wrap;

	.page-item{
		@include margin(0,4);

		.page-link{
			font-size:17px;
			color:$theme2;
			display:flex;
			align-items:center;
			justify-content:center;
			width:(45 / 17) * 1em;
			height:(45 / 17) * 1em;
			border-radius:0;
			border:1px solid #000;
			box-shadow:0 0 8px rgba(#777,0.4);
			color:#000;
			line-height:(45 / 17) * 1em;
		}

		&.active > .page-link,
		&:hover  > .page-link{
			background-color:$theme;
		}

	}

}