.article-vantagens{

	@include media-breakpoint-down(lg){
		text-align:center;
	}

	svg{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		circle{
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			transform: rotate(-90deg);
			transform-origin: center center;
		}

	}

	.circulo{
		position: relative;
		height:80px;
		width:80px;
		margin-left:auto;
		margin-right:auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.active{

		svg{

			circle{
				stroke-dashoffset: 0;
				transition: all 7s linear;
			}

		}

	}

	.numero{
		font-size:26px;
		font-family: 'latoblack';
		color:$theme2;
		line-height:1;

		.fas{
			font-size:20px;
			color:$theme;
			vertical-align:middle;
		}

	}

	&.article-vantagens-1{
		animation-delay:0.2s;
	}

	&.article-vantagens-2{
		animation-delay:0.4s;
	}

	&.article-vantagens-3{
		animation-delay:0.6s;
	}

}