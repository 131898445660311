.card-objetivos{
	margin-bottom:24px;
	text-align:center;

	.figure-header{
		margin-bottom:6px;
		min-height:102px;
		max-width:102px;
		margin-right:auto;
		margin-left:auto;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
		border-radius:100%;
		background-color:$theme;
		overflow:hidden;

		icon-awesome{
			width:48px;
			height:48px;
			margin-left:auto;
			margin-right:auto;
			position:relative;
			display:block;

			.icon-awesome{
				position:absolute;
				top:0;
				transition:background-color 0.6s linear;
				left:0;
				background-color:#000;
				width:100%;
				height:100%;
			}

		}

	}

	.card-content{
		padding-left:10px;
		padding-right:10px;

		.card-title{
			font-size:27px;
			font-family: 'lato-bold';
			color:$theme2;
			line-height:1;
		}

		.desc{
			line-height:1.2;
		}

	}

	&:hover{

		.figure-header{

			icon-awesome{

				.icon-awesome{
					background-color:#FFF;
				}

			}

		}

	}

}