.header-section{
	font-size:27px;
	margin-bottom:30px;

	.subtitle-section{
		color:$theme;
		font-size:(17 / 27) * 1em;
		margin-bottom:2px;
	}

	.title-section{
		color:$theme2;
		font-size:1em;
		font-family: 'lato-bold';
		margin-bottom:23px;
	}

	.box{
		display:block;
		width:11px;
		height:11px;
		background-color:$theme;
		margin-right:auto;
	}

	&.header-section-white{

		& > *{
			color:#FFF;
		}

		.box{
			background-color:#FFF;
		}

	}

}

.text-center{

	.header-section{

		.box{
			margin-left:auto;
		}

	}

}

.header-section.text-center{

	.box{
		margin-left:auto;
	}

}