.btn{
	font-size:15px;
	font-family: 'lato-bold';

	&.btn-pill{
		border-radius: 2rem;
	}

	&.btn-outline-black{
		@include button-outline-variant(#000,#000);
		color:#000;

		@include hover-focus{
			@include button-variant(lighten($theme,8%),lighten($theme,8%));	
			color:#000 !important;
		}

	}

	&.btn-outline-black-to-white{
		@include button-outline-variant(#000,#000);
		color:#000;

		@include hover-focus{
			@include button-variant(lighten(#FFF,10%),lighten(#FFF,10%));
			color:#000 !important;
		}

	}

	&.btn-theme{
		@include button-variant($theme,$theme);
		color:#FFF;
	}

	&.min-width-130{
		min-width:130px;
	}

	&.min-width-212{
		min-width:212px;
	}

	&.btn-radius-0{
		border-radius:0 !important;
	}

	&.btn-pdg-12-15{
		padding:12px 15px;
	}

}