@import "_fontfaces";

body{
	color:#777777;
	font-size:15px;
	font-family: 'latoregular';
}

// FONTS USADAS
#app{
	position:relative;
	overflow:hidden;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
	}

}

:focus{
	outline:none !important;
}

.slick-slider{

	img{
		@include img-fluid();
	}

}

.text-272727{
	color:#272727 !important;
}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

// FONTS USADAS
.lato-blk{
	font-family: 'latoblack';
}

.lato-bld{
	font-family: 'lato-bold';
}

.lato-reg{
	font-family: 'latoregular';
}

.lato-semi-bld{
	font-family: 'latosemibold';
}

.roboto-reg{
	 font-family: 'robotoregular';
}

.square,
.segura-carousel-sobre .owl-nav .fas{
	width:(42 / 16) * 1em;
	height:(42 / 16) * 1em;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-wrap:wrap;
	color:#000;
	background-color:#FFF;
	border:1px solid currentColor;
	font-size:16px;
	font-weight:bold;
	transition:all 0.6s linear;

	@include hover-focus{
		background-color:$theme;
		border-color:$theme;
		color:#000;
	}

}

.segura-carousel-sobre{

	.owl-stage-outer{
		z-index:200;
	}

	.owl-nav{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:space-between;
		padding-bottom:21px;

		& > *{
			position:relative;
			z-index:300;

			&.owl-prev{
				float:left;
				left:-6px;	
			}

			&.owl-next{
				float:right;
				right:-6px;
			}
			
		}

	}

}

svg{
	// overflow:hidden;
	// display:inline-block;
	// margin:0;
	// border:none;
}

.zoomContainer{
	display:none !important;
}

.slick-slider{

	.slick-track{

		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}

.embed-responsive-600x600{

	&:before{
		padding-top:100%;
	}

}