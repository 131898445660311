.topo{

	.bg-menu{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		background-color:rgba(#000,0.5);
		opacity:0;
		transition:opacity 0.6s linear;
	}

	.nav-content{

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
			@include make-container();
			@include make-container-max-widths();
			align-items:center;
		}

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			left:-230px;
			width:230px;
			height:100%;
			overflow:auto;
			transition:left 0.6s linear;
			background-color:#FFF;
			z-index:1000;
			border-right:1px solid #000;

			.logo{
				padding:30px;
				text-align:center;
				margin:0 auto;
			}

		}

		.logo{
			max-width:176px;
			margin-right:auto;

			@include media-breakpoint-only(lg){
				margin-right:40px;
			}
			
			@include media-breakpoint-up(lg){
				min-height:120px;
				position:relative;
				min-width:176px;

				img{
					position:absolute;
					top:0;
					left:0;
					width:100%;
					z-index:400;
					min-height:183px;
				}

			}

		}

	}

	&.fx{

		@include media-breakpoint-up(lg){
			position:fixed;
			top:0;
			left:0;
			width:100%;
			background-color:#FFF;
			z-index:1000;
			animation:fadeInDown 0.6s linear;
			box-shadow:0 0 8px rgba(#000,0.5);
		}

		.mbl-controls{
			animation:fadeInDown 0.6s linear;
			position:fixed;
			top:0;
			left:0;
			width:100%;
			z-index:600;
		}

	}

}

body{

	&.menu-active{

		.topo{

			.nav-content{
				left:0;
			}

		}

	}

	&.menu-active{


		.btn-responsivo{
			transform:rotate(45deg);

			.bar{

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

				@include beforeAfter{
					transform:rotate(90deg);
				}

			}

		}

	}

	&.sombra{

		.topo{

			.bg-menu{
				height:100%;
				opacity:1;
				z-index:700;
			}

		}

	}

}