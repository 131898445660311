.card-depoimento{
	margin-bottom:16px;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.desc{
		font-style:italic;
		margin-bottom:14px;
	}

	.author{
		color:$theme;

		@include beforeAfter{
			content:'-';
			display:inline-block;
		}

		&:before{
			margin-right:2px;
		}

		&:after{
			margin-left:2px;
		}

	}

}