@font-face {
    font-family: 'latoblack';
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot');
    src: url('#{$fonts}/lato_black/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_black/lato-black-webfont.woff') format('woff'),
         url('#{$fonts}/lato_black/lato-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_black/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'lato-bold';
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot');
    src: url('#{$fonts}/lato-bold/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.woff') format('woff'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato-bold/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot');
    src: url('#{$fonts}/lato_regular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.woff') format('woff'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/lato_regular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latosemibold';
    src: url('#{$fonts}/Lato-SemiBold/lato-semibold-webfont.eot');
    src: url('#{$fonts}/Lato-SemiBold/lato-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Lato-SemiBold/lato-semibold-webfont.woff2') format('woff2'),
         url('#{$fonts}/Lato-SemiBold/lato-semibold-webfont.woff') format('woff'),
         url('#{$fonts}/Lato-SemiBold/lato-semibold-webfont.ttf') format('truetype'),
         url('#{$fonts}/Lato-SemiBold/lato-semibold-webfont.svg#latosemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'robotoregular';
    font-display: optional;
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot');
    src: url('#{$fonts}/roboto_regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.woff') format('woff'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/roboto_regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
