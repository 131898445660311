.custom-tab-js{

	@include media-breakpoint-down(md){
		text-align:center;
	}

	.custom-header{
		width:100%;
		max-width:594px;
		margin-right:auto;
		margin-left:auto;
		font-size:17px;
		padding-top:20px;
		margin-bottom:40px;

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;
		}

		.custom-item{
			display:block;
			text-align:center;
			padding:14px 20px;
			position:relative;
			flex-grow:1;

			@include media-breakpoint-down(md){
				margin-bottom:15px;
			}

			&:before{
				content:'';
				display:block;
				position:absolute;
				top:100%;
				left:0;
				width:100%;
				height:6px;
				background-color:#EDEDED;
				transition:background-color 0.6s linear;
			}

			&.maior{
				
				@include media-breakpoint-up(lg){
					max-width:350px;
				}

			}

			&.menor{
				
				@include media-breakpoint-up(lg){
					max-width:244px;
				}

			}

			&:hover{
				color:lighten($theme,15%);

				&:before{
					background-color:lighten($theme,15%);
				}

			}

			&.active{
				color:$theme;

				&:before{
					background-color:$theme;
				}

			}

		}

	}

	.custom-contents{

		& > *{
			display:none;

			&.active{
				display:block !important;
				animation:fadeIn 0.6s linear;
			}

			.title-tab{
				font-size:27px;
				color:$theme;
				margin-bottom:22px;
				font-family: 'lato-bold';
			}

		}

	}

}