.form-gray{

	form{

		.input-group,
		.form-group{
			margin-bottom:10px;
			
			.form-control{
				background-color:#F5F5F5;
				border-color:#F5F5F5;
				border-radius:0;
				min-height:50px;
				color:$theme2;
				font-family: 'latosemibold';
				padding-left:30px;
				@include sombra(#F5F5F5);
				@include place($theme2);
			}

			textarea.form-control{
				padding-top:16px;
				resize:none;
			}

		}

	}

}

.form-max-918{
	
	form{
		max-width:918px;
		margin-left:auto;
		margin-right:auto;
	}

}