.banner{
	text-align:center;

	.carousel-item{
		margin-bottom:0;
	}

	.carousel-controls{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		justify-content:center;
		align-items:center;
		flex-wrap:wrap;

		.clearfix{
			width:100%;
			padding-left:15px;
			padding-right:15px;
			max-width:1170px;

			& > *{
				z-index:200;
				position:relative;
			}

		}

	}

}

.vantagens{
	background-color:#F5F5F5;
	color:#777777;
	font-size:17px;
	padding-top:28px;
	padding-bottom:14px;

	.col-lg-auto{

		@include media-breakpoint-up(lg){
			padding-right:0;
		}

	}

	.col-lg-4{

		& + .col-lg-4{

			@include media-breakpoint-down(lg){

				&:before{
					content:'';
					display:block;
					width:100%;
					height:1px;
					background-color:#FFF;
					margin-bottom:20px;
				}

			}

			@include media-breakpoint-up(lg){

				&:before{
					content:'';
					display:block;
					width:1px;
					position:absolute;
					right:100%;
					top:0;
					height:100%;
					background-color:#FFF;
				}

			}

		}

	}

}

.historia{
	padding-top:26px;
	padding-bottom:20px;

	.row{
		border-bottom:1px solid #F5F5F5;
	}

}

.controls-carousel-solo{

	&.max-100{

		.clearfix{
			max-width:100px;
			width:100%;
			margin-left:auto;

			@include media-breakpoint-down(md){
				margin-right:auto;
			}

		}

	}

}

.segura-carousel{
	position:relative;

	.slick-slider{
		z-index:20;
	}

	.controls-carousel{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;

		.clearfix{
			width:100%;
			padding-bottom:21px;

			& > *{
				z-index:200;
				position:relative;
			}

		}

	}

}

.depoimentos{
	background-color:#F5F5F5;

	.d-lg-flex{

		& > *{
			min-height:349px;
		}

		.parte-img{
			background-repeat:no-repeat;
			background-size:cover;
			background-position:left left;

			@include media-breakpoint-up(lg){
				width:(936 / 1920) * 100%;
				background-position:center center;
			}

		}

		.parte-text{
			padding-top:26px;
			padding-bottom:26px;
			padding-left:34px;
			padding-right:10px;

			@include media-breakpoint-down(md){
				text-align:center;

				.box{

					margin:0 auto;

				}

			}

			@include media-breakpoint-up(lg){
				width:(984 / 1920) * 100%;
				max-width:580px;
				margin-right:auto;
			}

		}

	}

}

.conheca{
	padding-top:25px;
	padding-bottom:6px;
	background-repeat:no-repeat;
	background-position:top right;
	background-size:cover;
}

.casos{
	position:relative;
	padding-top:26px;
	padding-bottom:2px;

	&:before{
		content:'';
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		background-color:#F5F5F5;
		height:100%;
		max-height:612px;
		z-index:-2;
	}

	.container{
		border-bottom:1px solid #F5F5F5;
	}

}

.videos{
	padding-top:25px;
	padding-bottom:8px;
}