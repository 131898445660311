.redes-sociais{

	&.redes-sociais-rodape{
		
		& > *{
			font-size:20px;
			width:(51 / 20) * 1em;
			height:(47 / 20) * 1em;
			background-color:#FFF;
			color:#000;
			display:inline-flex;
			align-items:center;
			justify-content:center;
			@include margin(0,10);

			@include hover-focus{
				color:#FFF;
				background-color:#000;
			}

		}

	}

}