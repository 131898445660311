.menu-principal{
	list-style-type:none;
	margin-bottom:0;
	padding-left:0;
	font-family: 'latosemibold';

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		flex-grow:1;
		align-items:center;
		max-width:772px;
		margin-left:auto;
	}

	& > li{

		& > a{

			@include media-breakpoint-down(md){
				display:block;
				padding:10px 12px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

			@include media-breakpoint-up(lg){
				display:block;
				padding:13px 2px;
				position:relative;

				&:before{
					content:'';
					display:block;
					position:absolute;
					bottom:0;
					left:0;
					width:0;
					transition:width 0.6s linear;
					height:5px;
					background-color:$theme;
				}

			}

		}

		&.active,
		&:hover{

			& > a{

				@include media-breakpoint-down(md){
					background-color:$theme;
					color:#FFF;
					border-color:darken($theme,10%);
				}

				@include media-breakpoint-up(lg){
					color:$theme;

					&:before{
						width:100%;
					}

				}

			}

		}

		&.li-custom{

			@include media-breakpoint-down(md){
				padding:15px 30px;
			}

			& > .btn{
				font-family: 'latosemibold';

				&:before{
					display:none;
				}

			}

		}

		&.li-dropdown{
			position:relative;
			display:flex;
			flex-wrap:wrap;
			justify-content:space-between;

			.item{
				width:80%;
				flex-grow:1;
			}

			.menu-dropdown{
				list-style-type:none;
				margin-bottom:0;

				@include media-breakpoint-down(md){
					padding-left:0;
					width:100%;
				}

				li{

					a{
						transition:all 0.6s linear;

						@include media-breakpoint-down(md){
							border-top:1px solid transparent;
							border-bottom:1px solid transparent;
							display:block;
							width:100%;
							padding:10px 15px;
						}

					}

				}

			}

			.btn{
				width:20%;
				box-shadow:none;
				border-radius:0;

				&[aria-expanded="true"]{

					.fa-plus{
						@extend .fa-minus;
					}

				}

				@include media-breakpoint-up(lg){
					display:none;
				}

			}

			@include media-breakpoint-up(lg){

				&:hover{

					.menu-dropdown{
						display:block !important;

						@include beforeAfter{
							content:'';
							display:block;
							position:absolute;
							bottom:100%;
						}

						&:before{
							border:14px solid transparent;
							border-bottom-color:$theme;
							z-index:299;
							left:28px;
						}

						&:after{
							width:100%;
							left:0;
							border-top:20px solid transparent;
						}

					}

				}

				.menu-dropdown{
					padding-top:12px;
					padding-bottom:10px;
					position:absolute;
					top:calc(100% + 16px);
					left:0;
					padding-left:25px;
					padding-right:25px;
					min-width:230px;
					background-color:#FFF;
					z-index:1000;
					min-height:266px;
					box-shadow:0 0 8px rgba(#000,0.5);

					li{
						display:flex;
						align-items:center;
						flex-wrap:wrap;

						&:hover,
						&.active{

							a{
								color:$theme;

								&:before{
									width:23px;
								}

							}

						}

						a{
							display:block;
							padding:11px 0;

							&:before{
								content:'';
								display:inline-block;
								width:0;
								height:3px;
								background-color:$theme;
								vertical-align:middle;
								margin-right:12px;
								transition:width 0.6s linear;
							}

						}

					}

				}

			}

			@include media-breakpoint-down(md){

				.menu-dropdown{

					li{

						&.active,
						&:hover{
							background-color:$theme;
							color:#FFF;
							border-color:darken($theme,20%);
						}

					}

				}

			}

		}

	}

}